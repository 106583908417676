<template>
  <div>
   <!-- <script @load="authLoaded" src="https://secure.aadcdn.microsoftonline-p.com/lib/1.0.15/js/adal.min.js" integrity="sha384-lIk8T3uMxKqXQVVfFbiw0K/Nq+kt1P3NtGt/pNexiDby2rKU6xnDY8p16gIwKqgI" crossorigin="anonymous" type="application/javascript"></script> -->
   <script type="application/javascript" @load="authLoaded" src="https://alcdn.msauth.net/browser/2.14.2/js/msal-browser.min.js"></script>

  </div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { EventBus } from '@/plugins/eventbus.js';
import accountService from "@/services/accountService";
import tenants from '@/data/tenants.config'
import i18n from '@/i18n'
// https://docs.microsoft.com/en-us/javascript/api/overview/msteams-client?view=msteams-client-js-latest
//import * as microsoftTeams from "@microsoft/teams-js";
const tenant = tenants.current();
//const microsoftTeams = window.microsoftTeams;

export default {
  components: {
  },
  props: {
    teamsWrapper: Object
  },
  data: () => ({
    authConfig: {  
      clientId: process.env.VUE_APP_MICROSOFT_CLIENT_ID,
      redirectUri: window.location.origin + "/apps/teams-v2/auth/end-microsoft",
      cacheLocation: "localStorage",
      navigateToLoginRequestUrl: false,
    },
  }),


  methods: {
    async authLoaded() {
      this.tryCompleteFlow();
    },
    async tryCompleteFlow() {
      console.log('teams available in auth start', this.teamsWrapper, window.AuthenticationContext, this.teamsWrapper.teamsContext);
      if (this.teamsWrapper.microsoftTeams && window.AuthenticationContext && this.teamsWrapper.teamsContext) {
        // Setup extra query parameters for ADAL
        // - openid and profile scope adds profile information to the id_token
        // - login_hint provides the expected user name
        if (this.teamsContext.upn) {
            this.authConfig.extraQueryParameters = `scope=${process.env.VUE_APP_MICROSOFT_SCOPE.replaceAll(',', '+')}&login_hint=${encodeURIComponent(this.teamsContext.upn)}`;
        } else {
            this.authConfig.extraQueryParameters = `scope=${process.env.VUE_APP_MICROSOFT_SCOPE.replaceAll(',', '+')}`;
        }
        // Use a custom displayCall function to add extra query parameters to the url before navigating to it
        let self = this;
        this.authConfig.displayCall = function (urlNavigate) {
            if (urlNavigate) {
                if (self.authConfig.extraQueryParameters) {
                    urlNavigate += "&" + self.authConfig.extraQueryParameters;
                }
                window.location.replace(urlNavigate);
            }
        }
        console.log('calling login ', window.AuthenticationContext, this.authConfig);
        // Navigate to the AzureAD login page
        let authContext = new window.AuthenticationContext(this.authConfig);

        let user = authContext.getCachedUser();
        if (user) {
          if (user.userName !== this.teamsContext.upn) {
            // User doesn't match, clear the cache
            authContext.clearCache();
          }
        }
        // Get the id token (which is the access token for resource = clientId)
        let token = authContext.getCachedToken(this.authConfig.clientId);
        if (token) {
          this.teamsWrapper.microsoftTeams.authentication.notifySuccess();
        }
        else {
          authContext.login();
        }
      }
    }    
  },
  computed: {

  },
  watch: {
    teamsWrapper() {
      this.tryCompleteFlow();
    },
    teamsWrapper() {
      console.log('teams available in auth start', window.AuthenticationContext, window.AuthenticationContext);
      this.tryCompleteFlow();
    }
  }
};
</script>
<style lang="scss" scoped>
  

</style>

